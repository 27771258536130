import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Fade from "react-reveal/Fade"
import Breadcrumbs from "src/utils/Breadcrumbs"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import BlockCta from "src/components/Blocks/BlockCta"
import CrossfadeImage from "react-crossfade-image"
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms"

const ArchivePostItem = props => {
  return (
    <>
    {props.data.architects_download.url &&
    <Link
      to={props.data.architects_download.url || null}
      className="group col-span-1 text-left border-l border-gold mb-12"
      title={`${props.data.title.text}`}
      download
    >
      <Fade>
        <div className="bg-white door-images w-full relative -ml-px">
          <CrossfadeImage
            src={props.data.pricing_image.url}
            alt={props.data.pricing_image.alt}
            containerClass={`absolute top-0 left-0 w-full flex justify-center items-end transition duration-500 ease-in-out group-hover:opacity-70 h-full`}
          />
        </div>
        <div className="p-6 pb-0">
          <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-4 group-hover:text-gold duration-500 ease-in-out">
            {props.data.title.text}
          </h2>
          <div className="description text-sm">
            Files include GSM, PDF, IFC and DWF, for our internal door style,
            Gio.
          </div>
          <i className="fal text-xl mt-6 text-gold group-hover:text-darkgrey duration-500 ease-in-out fa-arrow-to-bottom"></i>
        </div>
      </Fade>
    </Link>
    }
    </>
  )
}

const ArchivePostsContainer = props => {
  return (
    <div className="blog-items">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {props.data.map((post, index) => {
          return <ArchivePostItem {...post.node} key={index} />
        })}
      </div>
    </div>
  )
}

const ArchitectsDownloads = props => {
  const current = new Date()
  const nextYear = new Date()
  nextYear.setFullYear(current.getFullYear() + 1)

  const [cookie, setCookie] = useCookies(["architect_cookie"])
  const [state, setState] = useState(false)

  useEffect(() => {
    setState(cookie.architect_cookie)
  }, [cookie.architect_cookie])

  function handleCookieClick() {
    setCookie("architect_cookie", true, { path: "/", expires: nextYear })
    setState(true)
  }

  const DownloadsForm = props => {
    const { register, formState: { errors }, handleSubmit } = useForm()
    const [cookies] = useCookies()
    const gclid = cookies.gclid

    const netlify = useNetlifyForm({
      name: "architects-downloads",
      honeypotName: "bot-field",
      onSuccess: (response, context) => {
        console.log("Successfully sent form data to Netlify Server")
        window.dataLayer.push({event: 'form-submission', formName: 'architects-downloads'});
      },
    })

    const onSubmit = data => {
      netlify.handleSubmit(null, data)
      handleCookieClick()
    }

    return (
      <Fade>
        <div className="container pt-0">
          <div className="flex flex-row relative flex-wrap lg:flex-nowrap xl:mb-0 lg:mb-24">
            <div className="w-full lg:w-7/12">
              <img
                className="lg:w-100 w-full"
                alt="Brochure form background"
                src={
                  "https://images.prismic.io/deuren/2e39549c-393a-47ad-9841-37ba20caa788_9fd13761-rosewood-internal-door-vario-4-6.jpg?auto=compress%2Cformat&rect=0%2C0%2C800%2C1067&w=1200&h=900"
                }
                width="1200"
              />
            </div>
            <NetlifyFormProvider {...netlify}>
              <NetlifyFormComponent
                id="architects-downloads-form"
                className="bg-white lg:mt-12 lg:p-16 p-6 w-full lg:w-3/5 lg:absolute lg:right-0"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Honeypot />
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="lg:w-1/2">
                    <label htmlFor="first_name" className="forminputlabel">
                      First name *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="First name"
                      name="first_name"
                      {...register("first_name",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.first_name && "First name is required"}
                    </span>
                  </div>
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="last_name">
                      Last name *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="Last name"
                      name="last_name"
                      {...register("last_name",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.last_name && "Last name is required"}
                    </span>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="email_address">
                      Email address *
                    </label>
                    <input
                      className="forminput"
                      type="email"
                      placeholder="Email address"
                      name="email_address"
                      {...register("email_address",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.email_address && "Email address is required"}
                    </span>
                  </div>
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="phone_number">
                      Phone number *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="Phone number"
                      name="phone_number"
                      {...register("phone_number",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.phone_number && "Phone number is required"}
                    </span>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="w-full">
                    <label className="forminputlabel" htmlFor="email_address">
                      Company name *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="Company name"
                      name="company_name"
                      {...register("company_name",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.company_name && "Company name is required"}
                    </span>
                  </div>
                </div>
                {/* <FormJourneyStageInterest register={register} errors={errors}/> */}
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="w-full">
                    <label className="forminputlabel" htmlFor="newsletter">
                      We'd love to keep in touch
                    </label>
                    <p className="formbodytext mb-6">
                      We'd like to keep you up to date with the latest product
                      information and news from Deuren by email. We’ll always
                      treat your personal details with the utmost care and will
                      never sell them to other companies for marketing purposes.
                      All emails include an unsubscribe link. You may opt-out at
                      any time. See our Privacy Policy.
                    </p>
                    <label className="flex justify-start items-start gap-2">
                      <input
                        type="checkbox"
                        className="opacity-0 absolute"
                        name="newsletter"
                        {...register("newsletter")}
                      />
                      <i className="far fa-circle"></i>
                      <i className="far fa-check-circle"></i>
                      <div className="formcheckboxlabel" htmlFor="newsletter">
                        I agree to receive occasional emails containing the
                        latest product information and news from Deuren.
                      </div>
                    </label>
                    <span className="formerror">
                      {errors.newsletter &&
                        "Please tick the box above to submit this form"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row space-x-6 mb-6">
                  <div className="w-full">
                    <input
                      type="text"
                      className="hidden"
                      name="gclid"
                      value={gclid}
                      {...register("gclid")}
                    />
                    <input
                      className="btn cursor-pointer outline-none"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </NetlifyFormComponent>
            </NetlifyFormProvider>
          </div>
        </div>
      </Fade>
    )
  }

  const ArchitectsDownloadsListing = props => {
    // Switch tabs when you click
    const [toggled, setToggle] = useState("All")
    function handleTabClick(props) {
      setToggle(props)
    }

    const combinedData = [...props.internal, ...props.front, ...props.garage]

    return (
      <Fade>
        <div className="w-full bg-white py-6">
          <div className="py-0 md:px-0 px-6 overflow-x-auto">
            <div className="space-x-4 w-max m-auto">
              <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                  toggled === "All" ? "active" : ""
                }`}
                onClick={() => handleTabClick("All")}
              >
                All Downloads
              </button>
              <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                  toggled === "Internal" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Internal")}
              >
                Internal Doors
              </button>
              <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                  toggled === "Front" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Front")}
              >
                Front Doors
              </button>
              <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                  toggled === "Garage" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Garage")}
              >
                Garage Doors
              </button>
            </div>
          </div>
        </div>
        <section className="bg-offwhite overflow-hidden downloads">
          <div className="container">
            <div
              className={`${toggled === "All" ? "visible" : "invisible h-0"}`}
            >
              <div
                className={`transition-opacity duration-1000 ${
                  toggled === "All" ? "opacity-100" : "opacity-0"
                }`}
              >
                <ArchivePostsContainer data={combinedData} />
              </div>
            </div>
            <div
              className={`${
                toggled === "Internal" ? "visible" : "invisible h-0"
              }`}
            >
              <div
                className={`transition-opacity duration-1000 ${
                  toggled === "Internal" ? "opacity-100" : "opacity-0"
                }`}
              >
                <ArchivePostsContainer data={props.internal} />
              </div>
            </div>
            <div
              className={`${toggled === "Front" ? "visible" : "invisible h-0"}`}
            >
              <div
                className={`transition-opacity duration-1000 ${
                  toggled === "Front" ? "opacity-100" : "opacity-0"
                }`}
              >
                <ArchivePostsContainer data={props.front} />
              </div>
            </div>
            <div
              className={`${
                toggled === "Garage" ? "visible" : "invisible h-0"
              }`}
            >
              <div
                className={`transition-opacity duration-1000 ${
                  toggled === "Garage" ? "opacity-100" : "opacity-0"
                }`}
              >
                <ArchivePostsContainer data={props.garage} />
              </div>
            </div>
          </div>
        </section>
      </Fade>
    )
  }

  const crumbs = [
    {
      label: "Architects Downloads",
      href: "/architects-downloads",
      active: true,
    },
  ]

  return (
    <article>
      <Seo title={"Architects Downloads"} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <section className={`bg-offwhite`}>
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              Architects Downloads
            </h2>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-2xl mt-6 m-auto">
              This section contains 3D CAD and BIM files of products, features
              and technical data that may need consideration by architects and
              self-builders. Click on any of the files below to download. For
              advice on our documentation, please{" "}
              <Link to="/contact/">contact the team</Link>.
            </p>
          </Fade>
        </div>
      </section>
      <div>
        {state && (
          <ArchitectsDownloadsListing
            internal={props.data.allPrismicInternalDoor.edges}
            front={props.data.allPrismicFrontDoor.edges}
            garage={props.data.allPrismicGarageDoor.edges}
          />
        )}
      </div>
      <div>{!state && <DownloadsForm />}</div>
      <BlockCta />
    </article>
  )
}

export default ArchitectsDownloads

export const ArchitectsDownloadsQuery = graphql`
  query ArchitectsDownloadsQuery {
    allPrismicInternalDoor(
      filter: { data: { architects_download: { url: { ne: "" } } } }
    ) {
      edges {
        node {
          data {
            architects_download {
              url
            }
            pricing_image {
              url
              alt
            }
            title {
              text
            }
          }
        }
      }
    }
    allPrismicFrontDoor(
      filter: { data: { architects_download: { url: { ne: "" } } } }
    ) {
      edges {
        node {
          data {
            architects_download {
              url
            }
            pricing_image {
              url
              alt
            }
            title {
              text
            }
          }
        }
      }
    }
    allPrismicGarageDoor(
      filter: { data: { architects_download: { url: { ne: "" } } } }
    ) {
      edges {
        node {
          data {
            architects_download {
              url
            }
            pricing_image {
              url
              alt
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
